import React from "react";
import Slider from "rc-slider";

const ESGSlider = ({ item, index, max, onChange }) => {
  // the handle of the slider
  const handleStyle = (item) => {
    return {
      borderColor: item.color,
      width: 28,
      height: 28,
      marginLeft: "-0px",
      marginTop: "-12px",
      color: item.color,
    };
  };

  // the current area of the slider
  const trackStyle = (item) => {
    return {
      backgroundColor: item.color,
    };
  };

  // the back most layer of the slider
  const railStyle = (item) => {
    return {
      backgroundColor: `${item.color}50`,
    };
  };

  return (
    <span>
      <Slider
        min={0}
        max={max ?? 100}
        trackStyle={trackStyle(item)}
        handleStyle={handleStyle(item)}
        railStyle={railStyle(item)}
        onChange={(nextValues) => {
          onChange(nextValues, index);
        }}
        value={item.value}
        draggableTrack
        pushable
      />
    </span>
  );
};

export default ESGSlider;
