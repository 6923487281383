import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Background from "./components/Background";
import About from "./pages/about";
import Admin from "./pages/admin";
import { SignIn, SignUp, SignOut } from "./pages/auth";
import SubmissionReceived from "./pages/submission-received";
import Play from "./games";

import "rc-slider/assets/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "rsuite/dist/rsuite.min.css";

function App() {
  return (
    <div className="App">
      <Background />
      <Router>
        <Header />
        <Container fluid style={{ paddingBottom: "16px" }}>
          <Routes>
            <Route path="/" element={<Play />} />
            <Route path="/:gameId" element={<Play />} />
            <Route
              path="/submission-received/:id"
              element={<SubmissionReceived />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
