import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CircularProgress, Stack, Typography } from "@mui/material";
import { Container, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

/* 


  Frontend submission steps:

  1. Make a fetch request
  2. Parse the JSON response
  3. Display the results to the player
  
  */

const SubmissionReceived = () => {
  // results loaded based on the submission id
  // const [results, setResults] = React.useState(null);

  // get the id from the url parameters
  const { id } = useParams();

  // Query for the submission results
  React.useEffect(() => {
    console.log("getResults for ", id);
    // getProfile(handle).then(setUser);
  }, [id]);

  /* call this url to get begin processing the R script */
  // const base = 'http://127.0.0.1:5001';
  // const url = `${base}/esgmachine-36d4e/us-central1/r-executeExAsync?id=${id}`;

  // Draw the view
  return (
    <>
      <Container className="py-4">
        <Alert variant="success">
          <span className="alert-heading h4">
            <FontAwesomeIcon icon="fa-check-circle" />
            {" Submission Received!"}
          </span>
          <p>
            Thank you! Your submission has been received, and your contribution
            is greatly appreciated!
          </p>
        </Alert>

        {/* <Stack alignItems="center" spacing={2}>
          <CircularProgress />
          <Typography>Your results are processing...</Typography>
        </Stack> */}
      </Container>
    </>
  );
};

export default SubmissionReceived;
