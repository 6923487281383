import React, { useState } from "react";

import { CircularProgress } from "@mui/material";
import { Steps } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";

import useIpAddress from "utils/useIpAddress";
import Register from "components/Register";
import GameService from "services/GameService";
import SubmissionService from "services/SubmissionService";
import PointsGameView from "./points";
import SortGameView from "./sort";
import Realestate from "./realestate";

/// The initial game landing page which loads the remote game config
/// displaying the appropriate game type while also providing context
const Play = () => {
  const ipAddress = useIpAddress();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const gameId = pathname.substr(1); // Removes the leading slash

  const [game, setGame] = useState(null);
  const [scores, setScores] = useState([]);
  const [pollResults, setPollResults] = useState(null);

  // derived state variables
  const isLoading = game === null;
  const name = game?.name ?? "Game";
  const rounds = game?.rounds.length ?? 1;
  const currentRound = scores.length;
  const progressText = `${currentRound + 1} of ${rounds} rounds`;

  const userAnsweredPreQuestions =
    game?.questions?.pre?.length > 0
      ? Object.keys(pollResults?.pre ?? {}).length > 0
      : true;
  const userAnsweredPostQuestions =
    game?.questions?.post?.length > 0
      ? Object.keys(pollResults?.post ?? {}).length > 0
      : true;

  // Game derived vars
  const currentGame = game?.rounds[currentRound] ?? null;
  const currentMode = currentGame?.mode;

  const completedGamePlay = currentRound >= rounds;
  const userCompletedPolls =
    userAnsweredPreQuestions && userAnsweredPostQuestions;
  // Handle state changes
  React.useEffect(() => {
    function loadGame() {
      if (game == null) {
        GameService.getGame(gameId).then(setGame);
      }
    }
    loadGame();

    const handleRoundSubmitted = () => {
      console.log("Post submit called");
      if (currentRound >= rounds && userCompletedPolls) {
        console.log("Submitting...");
        // Add a new document with a generated id.
        SubmissionService.addSubmission(game.id, {
          profile: pollResults,
          rounds: scores,
          ipAddress: ipAddress ?? "0.0.0.0",
        }).then((result) => {
          navigate(`/submission-received/${result.id}`);
        });
      } else {
        console.log(`${currentRound} of ${rounds} completed`);
      }
    };

    handleRoundSubmitted();
  }, [
    scores,
    game,
    currentRound,
    navigate,
    pollResults,
    rounds,
    userCompletedPolls,
    gameId,
    ipAddress,
  ]);

  // Draws a timeline indicating the current round within the game
  const Timeline = () => {
    if (rounds === 1) return <></>;
    var elements = [];
    for (var i = 0; i < rounds; i++) {
      elements.push(<Steps.Item key={i} />);
    }
    return (
      <Steps className="py-4" current={currentRound}>
        {elements}
      </Steps>
    );
  };

  // Text Heading indicating the game name and current round
  const HeadingText = () => {
    return (
      <h1 className="flex-d justify-content-center ">
        {`${name} `}
        <sup>
          <small>{progressText}</small>
        </sup>
      </h1>
    );
  };

  // Game view for the active round's mode
  const GameContentView = () => {
    if (currentRound >= rounds) {
      return <></>;
    }
    var params = {
      game: game,
      round: currentRound,
      config: game?.rounds[currentRound] ?? {},
      onSubmit: handleGamePlay,
    };

    switch (currentMode) {
      default: // "points"
        return <PointsGameView {...params} />;
      case "sort":
        return <SortGameView {...params} />;
      case "realestate":
        return <Realestate {...params} />;
    }
  };

  const handleGamePlay = (data) => {
    let newScores = [...scores, data];
    setScores(newScores);
  };

  // Content to draw while the game loads
  const Content = () => {
    return (
      <>
        {!userAnsweredPreQuestions && (
          <Register
            title="Pre Game Questions"
            questions={game?.questions?.pre ?? {}}
            onSubmit={(value) => {
              setPollResults({ ...pollResults, ...{ pre: value } });
              console.log(pollResults);
            }}
          />
        )}

        {userAnsweredPreQuestions && !completedGamePlay && (
          <>
            <HeadingText />
            <Timeline />
            <GameContentView />
          </>
        )}

        {userAnsweredPreQuestions &&
          !userAnsweredPostQuestions &&
          completedGamePlay && (
            <Register
              title="Post Game Questions"
              questions={game?.questions?.post ?? {}}
              onSubmit={(value) => {
                setPollResults({ ...pollResults, ...{ post: value } });
              }}
            />
          )}
      </>
    );
  };

  // Body to draw while loading
  return <>{isLoading ? <CircularProgress /> : <Content />}</>;
};

export default Play;
