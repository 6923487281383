import React from "react";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Stack, Typography } from "@mui/material";

import { interpolateColors } from "utils/color-generator";
import { suggestedRangeForBaseline } from "./utils";

// In your component or a global configuration fil

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const MatrixChart = ({ scheme, baseline, inputControls, selection }) => {
  /* 
      computes the value given a baseline key
      takes a Lamda function to compute the outcome
    */
  const computeValueForBaseline = (
    title,
    handler = (baselineValue, totalValueChange) =>
      baselineValue + totalValueChange
  ) => {
    let baselineValue =
      Object.values(baseline).find((e) => e.title === title)?.value ?? 0;

    var totalValueChange = 0;

    // Loop over all rows within the selection
    for (var controlTitle in inputControls) {
      // the the selected input for the category
      let activeOption = activeOptionForInputControl(controlTitle);

      // Baseline Values entered into the selection
      const idx = Object.values(baseline ?? {}).findIndex(
        (e) => e.title === title
      );

      let valueChange = Number(
        Object.values(activeOption?.deltaValues ?? {})[idx]?.value ?? 0
      );

      // apply that value to total value change
      totalValueChange += valueChange;
    }

    // return result of the lambda function
    return handler(baselineValue, totalValueChange);
  };

  // gets the currently selected option on the category
  const activeOptionForInputControl = (title) => {
    let control = inputControls[title];
    let activeMark = control.options[selection[title] ?? 0];
    return activeMark;
  };

  // compute the percent change in values, to be displayed in the chart
  const computeCumulativeChartDataForBaseline = (title) =>
    computeValueForBaseline(
      title,
      (baselineValue, totalValueChange) => totalValueChange + baselineValue // (totalValueChange / baselineValue) * 100
    );

  // slightly modified mapping of the baseline values applying color scheme
  var viewModels = Object.values(baseline).map((model) => {
    const colors = interpolateColors(Object.values(baseline).length, scheme);
    model.color = colors[Object.values(baseline).indexOf(model)] ?? "000";
    return model;
  });

  // const values = Object.values(baseline).map((e) =>
  //   computeCumulativeChartDataForBaseline(e.title)
  // );

  // const X_MIN = Math.min(...values, 0, min).toFixed() ?? null;
  // const X_MAX = max ?? null; // Math.max(...values, 10);

  const ranges = React.useMemo(() => {
    const results = {};

    Object.keys(baseline).forEach((input) => {
      results[input] = suggestedRangeForBaseline(
        input,
        baseline,
        inputControls
      );
    });

    return results;
  }, [baseline, inputControls]);

  const suggestedRange = (index) => ranges[index]; // suggestedRangeForBaseline(index, baseline, inputControls);

  const options = (value, index) => ({
    indexAxis: "y",
    scaleBeginAtZero: false,
    spanGaps: true,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    aspectRatio: 40 / 9,
    scales: {
      y: {
        afterFit: function (scaleInstance) {
          scaleInstance.width = 110; // sets the width to 100px
        },
      },
      x: {
        min: value.chartMinX || suggestedRange(index)?.min,
        max: value.chartMaxX || suggestedRange(index)?.max,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value;
          },
        },
      },
    },
    plugins: {
      title: {
        align: "center",
        display: false,
        text: "Relative changes compared to a no-retrofitting-scenario",
      },
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const r = formatLabel(ctx.raw, ctx.dataIndex);

            return r;
          },
        },
      },
      subtitle: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        labels: {
          value: {
            backgroundColor: function (ctx) {
              return "#ffffff";
            },
            borderColor: "white",
            borderWidth: 2,
            borderRadius: 4,
            color: function (ctx) {
              return ctx.dataset.backgroundColor;
            },
            formatter: function (value, context) {
              return formatLabel(value, context.dataIndex);
            },
            padding: 1,
          },
        },
      },
    },
  });

  function formatRange(val, index) {
    const min = baseline[index]?.chartMinX || suggestedRange(index)?.min;
    if (val <= min) return [val, 0];
    return [min, val];
  }

  function formatLabel(val, index) {
    const min = baseline[index]?.chartMinX || suggestedRange(index)?.min;
    if (val <= min) return val[0].toFixed(2);
    return val[1].toFixed(2);
  }

  const dataForBaseline = (baseline, index) => {
    const { title } = baseline;
    const value = computeCumulativeChartDataForBaseline(title);
    return formatRange(value, index);
  };

  const chartDataForBaseline = (value, index) => {
    const { title, suffix } = value;
    const dataValues = [dataForBaseline(value, index)];
    return {
      labels: [`${title} ${suffix ?? ""}`],
      datasets: [
        {
          data: dataValues,
          borderColor: [viewModels.map((e) => e.color)[index]],
          backgroundColor: [viewModels.map((e) => e.color)[index]],
        },
      ],
    };
  };

  return (
    <Stack alignItems="center">
      <Typography variant="caption" fontWeight="bold">
        Relative changes compared to a no-retrofitting-scenario
      </Typography>
      {Object.values(baseline).map((e, i) => (
        <Bar
          key={i}
          options={options(e, i)}
          data={chartDataForBaseline(e, i)}
          style={{
            // position: "sticky",
            top: "1rem",
            minWidth: "275",
            maxWidth: "500",
          }}
        />
      ))}
    </Stack>
  );
};

export default MatrixChart;
