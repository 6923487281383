import React, { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { db } from "services/firebase";
import { Create, Update, Delete } from "./";
import moment from "moment";

const GameTools = () => {
  return (
    <div>
      <Create />
      <Delete />
    </div>
  );
};

function GamesTable() {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const unsubscribe = db
      .collection("games")
      .orderBy("timestamp", "desc")
      .onSnapshot((querySnapshot) => {
        var res = [];
        querySnapshot.forEach((doc) => {
          res.push({ id: doc.id, ...doc.data() });
        });
        setGames(res);
      });

    return unsubscribe;
  }, [setGames]);

  const closeModal = () => {
    setSelectedGame({});
    setShowDetails(false);
  };

  return (
    <>
      <Update game={selectedGame} show={showDetails} handleClose={closeModal} />

      <span>
        <h1 className="h3">Games</h1>
        <GameTools />
      </span>
      <Table
        height={800}
        data={games}
        onRowClick={(data) => {
          setSelectedGame(data);
          setShowDetails(true);
        }}
      >
        <Column width={65} fixed>
          <HeaderCell>Status</HeaderCell>
          <StatusCell dataKey="status" />
        </Column>

        <Column width={150} fixed>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column width={300} fixed>
          <HeaderCell>Created At</HeaderCell>
          <Cell>
            {(rowData) => {
              return moment(rowData.timestamp?.toDate() ?? "").format(
                "MMMM Do YYYY, h:mm:ss a"
              );
            }}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

const StatusCell = ({ rowData, dataKey, ...props }) => {
  const status = rowData[dataKey];
  const color =
    status === "active" ? "green" : status === "draft" ? "orange" : "red";
  return (
    <Cell {...props} align="center">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: color,
          }}
        />
      </div>
    </Cell>
  );
};

export default GamesTable;
