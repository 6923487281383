import { games, db } from "./firebase";
import Timestamp from "utils/Timestamp";

const getGames = async () => {
  var results = [];
  await games
    .orderBy("timestamp", "desc")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var game = {
          id: doc.id,
          ...doc.data(),
        };
        results.push(game);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return results;
};

// initialize a new game based on the most recent
// published game on the networks's database
const getGame = async (route) => {
  console.log("fetching game");
  // Get all active games
  const activeGamesSnapshot = await db
    .collection("games")
    .where("status", "==", "active")
    .get();

  const activeGames = activeGamesSnapshot.docs.map((doc) => {
    const data = doc.data();
    return { id: doc.id, ...data };
  });

  if (!activeGames || activeGames.length === 0) {
    const fallback = await getGames();
    console.warn("No active games, falling back ", fallback[0]);
    return fallback[0];
  }

  // Get the number of submissions for each active game
  const submissionsPromises = activeGames.map((game) => {
    return db
      .collection("submissions")
      .where("game", "==", db.collection("games").doc(game.id))
      .get();
  });
  const submissionsSnapshots = await Promise.all(submissionsPromises);
  const submissionsCounts = submissionsSnapshots.map(
    (snapshot) => snapshot.size
  );

  // Find the index of the game with the lowest number of submissions
  let minSubmissionsIndex = 0;
  for (let i = 1; i < submissionsCounts.length; i++) {
    if (submissionsCounts[i] < submissionsCounts[minSubmissionsIndex]) {
      minSubmissionsIndex = i;
    }
  }

  const routedGame = activeGames.find((e) => e.route === route);

  // Return the game with the lowest number of submissions
  return route?.length > 0 && routedGame
    ? routedGame
    : activeGames[minSubmissionsIndex];
};

// initialize a new game based on the most recent
// published game on the networks's database
const createGame = (params) => {
  let result = games
    .add({
      name: params.name,
      rounds: params.configs,
      questions: params.questions,
      status: params.status,
      route: params.name.toLowerCase().replace(" ", ""),
      timestamp: Timestamp(),
    })
    .then(() => {
      console.log("Document successfully written!");
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  return result;
};

// destroy all existing documents within the game collection
const nukeGames = (confirm) => {
  if (confirm()) {
    let batch = db.batch();
    games
      .get()
      .then(function (snapshot) {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .catch((e) => alert(e));
  }
};

// updates a model with new parameters
const updateGame = async ({ id, name, questions, status, configs }) => {
  return await games.doc(id).update({
    name: name,
    rounds: configs,
    questions: questions,
    status: status,
    route: name.toLowerCase().replace(" ", ""),
    timestamp: Timestamp(),
  });
};

// Deletes a game by id
const deleteGame = (id) => {
  let result = games
    .doc(id)
    .delete()
    .then(console.log("Document successfully deleted model!"))
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  return result;
};

const GameService = {
  getGames,
  getGame,
  createGame,
  nukeGames,
  updateGame,
  deleteGame,
};

export default GameService;
