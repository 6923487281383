import React, { Component } from "react";

import _ from "lodash";
import { Stack, Button, Grid } from "@mui/material";

import TestData from "./test-data";
import MatrixChart from "./Chart";
import MatrixControls from "./Controls";

// coordinates loading the game and presenting it
class Play extends Component {
  constructor({ config, onSubmit }) {
    super();
    this.onSubmit = onSubmit;
    this.state = {
      ...(config ?? TestData),
      selection: {},
    };
  }

  componentDidMount() {
    this.initializeGameRound();
  }

  initializeGameRound = () => {
    const { selection, inputControls } = this.state;

    var initialRound = {};
    for (var key in inputControls) {
      initialRound[key] = -1;
    }
    this.setState({
      selection: _.cloneDeep(initialRound),
      initial_state: initialRound,
    });

    if (!selection)
      return console.warn(
        "Failed to initialize game round. No game is mounted"
      );
  };

  handleDataChange = (data) => {
    this.setState((prevState) => ({
      selection: {
        ...prevState.selection,
        [data.index]: data.value,
      },
    }));
  };

  // pass the submission to the parent to handle
  set_submission = (newSubmission) => this.onSubmit(newSubmission);

  // Render the game scene
  render() {
    const { inputControls, baseline, selection, scheme } = this.state;

    // prepare submission and pass it to the parent to handle
    const handle_submit = () => {
      this.set_submission({
        initial_state: this.state.initial_state,
        final_state: this.state.selection,
      });
    };

    // Actions to perform on the current game round
    const ActionsFooter = () => {
      return (
        <Stack gap={1}>
          <Button variant="contained" onClick={handle_submit}>
            Next
          </Button>
        </Stack>
      );
    };

    const props = {
      ...{
        min: this.state.chartMinX,
        max: this.state.chartMaxX,
        scheme,
        baseline,
        inputControls,
        selection,
        onChange: this.handleDataChange,
      },
    };

    return (
      <>
        <Grid container spacing={1} padding={1} sx={{ height: "90dvh" }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ maxHeight: { xs: "auto", sm: "35dvh" } }}
          >
            <MatrixChart {...props} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MatrixControls
              sx={{
                overflow: "scroll",
                height: { xs: "45dvh", sm: "75dvh" },
              }}
              {...props}
            />
          </Grid>

          <Grid item xs={12} sx={{ height: "5dvh" }}>
            <ActionsFooter />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Play;
