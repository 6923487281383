import react, { useState } from "react";
import logo from "../imgs/logo.png";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { auth } from "../services/firebase";

function Header() {
  const [user, setUser] = useState(null);
  react.useEffect(() => {
    const unlisten = auth.onAuthStateChanged((authUser) => {
      authUser ? setUser(authUser) : setUser(null);
    });
    return () => {
      unlisten();
    };
  }, []);

  const AuthenticationLinks = () => {
    return (
      <>
        <Nav.Link as={NavLink} to="/signin">
          Sign In
        </Nav.Link>
        <Nav.Link as={NavLink} to="/signup">
          Sign Up
        </Nav.Link>
      </>
    );
  };

  const AuthenticatedLinks = () => {
    return (
      <>
        {user ? AdminLinks() : null}
        <Nav.Link as={NavLink} to="/signout">
          Sign Out
        </Nav.Link>
      </>
    );
  };

  const AdminLinks = () => {
    return (
      <Nav.Link as={NavLink} to="/admin">
        Admin
      </Nav.Link>
    );
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Link to="/">
          <Navbar.Brand>
            <img
              src={logo}
              width="28"
              height="28"
              className="d-inline-block align-top mx-2"
              alt="ESG Machine logo"
            />
            <b>ESGMACHINE</b>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="navbarScroll">
          {!user ? AuthenticationLinks() : AuthenticatedLinks()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
