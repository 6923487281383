import React from "react";
import { Icon } from "utils/fontawesome";
import { Form } from "react-bootstrap";

const EditCategoryRow = ({
  index,
  item: { name, icon, color, multiplier },
  readOnly,
  onChange,
}) => {
  function handleChange(event) {
    onChange({
      index: index,
      multiplier: event.target.value,
    });
  }

  return (
    <li key={`${index}-${name}`} className="list-group-item">
      <div className="d-flex justify-content-between">
        <span>
          <Icon icon={icon} color={color} />
          <strong className="px-2">{name}</strong>
        </span>

        <div>
          <Form.Control
            type="number"
            step="0.05"
            value={multiplier ?? 1}
            placeholder="Multiplier"
            onChange={handleChange}
            readOnly={readOnly}
          />
        </div>
      </div>
    </li>
  );
};

export default EditCategoryRow;
