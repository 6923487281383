import React from "react";

import { Form } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import GAME_MODE from "games/types";
import MatrixForm from "games/realestate/form";
import PointsForm from "games/points/form";
import SortForm from "games/sort/form";

function RoundForm({ config, onChange }) {
  const mode = config?.mode ?? "";
  const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

  // Radio buttons describing available game modes
  const GameModeRadioButton = () => {
    return (
      <div className=" d-grid gap-2">
        <ToggleButtonGroup type="radio" name="gameMode" defaultValue={mode}>
          {GAME_MODE.map(({ value, label, details }, index) => (
            <OverlayTrigger
              key={`${index}-overlay`}
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(details)}
            >
              <ToggleButton
                id={value}
                key={value}
                value={value}
                active={value === mode ?? "points"}
                variant={
                  value === mode ? "outline-primary" : "outline-secondary"
                }
                checked={value === mode}
                onClick={(e) => {
                  onChange({ mode: value });
                }}
              >
                {label}
              </ToggleButton>
            </OverlayTrigger>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  };

  // Input group for the Match Value (number for allocated points to add up to)
  const GameModeOptions = () => {
    const params = {
      config,
      onChange,
    };

    switch (mode) {
      default: // "points"
        return <PointsForm {...params} />;
      case "sort":
        return <SortForm {...params} />;
      case "realestate":
        return <MatrixForm {...params} />;
    }
  };

  return (
    <div className="h-100 p-5 bg-light border rounded-3">
      <Form.Group className="mb-3" controlId="mode">
        <Form.Label>Game Mode</Form.Label>
        <br />
        {GameModeRadioButton()}
      </Form.Group>
      {GameModeOptions()}
    </div>
  );
}

export default React.memo(RoundForm);
