import React, { useState } from "react";
import { Form, Button, Container, FloatingLabel } from "react-bootstrap";
import Auth from "services/auth";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();

  return (
    <Container fluid className="text-center">
      <Form className="form-signin">
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3">
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatingLabel>
        </Form.Group>{" "}
        <SubmitButton
          email={email}
          password={password}
          handler={(user) => {
            if (user) {
              navigate("/admin");
            }
          }}
        />
      </Form>
    </Container>
  );
};

const SubmitButton = ({ email, password, handler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const signup = async () => {
    try {
      setIsLoading(true);
      let user = Auth.signin(email, password);
      if (user) {
        console.log("user did authenticate from signin");
        handler(user);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  function LoadingButton() {
    return (
      <Button
        type="button"
        variant="primary"
        disabled={isLoading}
        onClick={!isLoading ? signup : null}
      >
        {isLoading ? "Loading…" : "Submit"}
      </Button>
    );
  }

  return <LoadingButton />;
};

export default SignInForm;
