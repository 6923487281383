import { auth, firebaseApp } from "./firebase";
// create a new user with email a password
async function signup(email, password) {
  return new Promise((resolve, reject) => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => userDidAuthenticate(user))
      .catch((error) => {
        const errorMessage = error.message;
        console.log("failed to register user", errorMessage);
        reject("Failed to signup");
      });
  });
}

// Log in an existing user
async function signin(email, password) {
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        userDidAuthenticate(user);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log("failed to register user", errorMessage);
        reject("Failed to signin ", errorMessage);
      });
  });
}

// Sign the current user out
async function signout() {
  auth
    .signOut()
    .then((user) => userDidAuthenticate(user))
    .catch((error) => console.log("error"));
}

// Manage state change of the user
async function userDidAuthenticate(user) {
  if (user) {
    // Get the user's ID token as it is needed to exchange for a session cookie.
    console.log("user is authenticated", user);
  } else {
    console.log("user is not authenticated");
  }
}

// role based authentication & access control
export async function userClaims() {
  let user = firebaseApp.auth().currentUser;
  if (!user) return { guest: true };
  user.getIdTokenResult().then((idTokenResult) => {
    return buildClaims(idTokenResult);
  });
}

function buildClaims(token) {
  const claims = token.claims;

  var res = [];
  if (!!claims) {
    res.push("user");
    if (claims.admin) res.push("admin");
  } else {
    res.push("guest");
  }

  return res;
}

const Auth = {
  signin,
  signup,
  signout,
  auth,
  userClaims,
};

export default Auth;
