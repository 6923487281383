import { FormGroup, FormLabel } from "@mui/material";
import ModelsPicker from "sections/admin/models/Picker";
import ColorSchemePicker from "components/ColorSchemePicker";
import { interpolateColors } from "utils/color-generator";

const SortForm = ({ config, onChange }) => {
  const mode = config.mode ?? "";
  const models = config.models ?? [];
  const scheme = config.scheme ?? "";

  var viewModels = models.map((model) => {
    const colors = interpolateColors(models.length, scheme);
    model.color = colors[models.indexOf(model)] ?? "000";
    return model;
  });

  return (
    <>
      <FormGroup className="mb-3">
        <ColorSchemePicker scheme={scheme} onChange={onChange} />
      </FormGroup>

      <FormGroup>
        <FormLabel>Social Issues</FormLabel>
        <ModelsPicker
          mode={mode}
          value={viewModels}
          onChange={(e) => {
            onChange({ models: e.models || [] });
          }}
        />
      </FormGroup>
    </>
  );
};

export default SortForm;
