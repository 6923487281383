const GAME_MODE = [
  {
    value: "points",
    label: "Points",
    details: "Players allocate points to items of their choosing.",
  },
  {
    value: "sort",
    label: "Sort",
    details: "Players arrange a list of items prioritizing them by importance.",
  },
  {
    value: "realestate",
    label: "Realestate",
    details:
      "Players see how their preferences effect the cost/value of a home",
  },
];

export default GAME_MODE;
