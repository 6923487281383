// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCIQOj5LZAAA-i_fptPbgU9oqDd3gmRE3A",
  authDomain: "esgmachine-36d4e.firebaseapp.com",
  projectId: "esgmachine-36d4e",
  storageBucket: "esgmachine-36d4e.appspot.com",
  messagingSenderId: "881118885581",
  appId: "1:881118885581:web:a3caebfe5f4e904c91db84",
  measurementId: "G-LSC0GRM2QW",
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);
const functions = firebase.functions();
const db = firebaseApp.firestore();

// setup firebase emulators
if (process.env.NODE_ENV === "development") {
  console.log("Attempting to connect to Emulators");
  db.useEmulator("localhost", 8080);
  firebase.auth().useEmulator("http://localhost:9099");
}

// Use these for db & auth
const auth = firebase.auth();

// state will be persisted even when the browser window is closed or the activity is destroyed in React Native. An explicit sign out is needed to clear that state
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const games = db.collection("games");
const models = db.collection("models");
const submissions = db.collection("submissions");

// exports
export { firebaseApp, auth, db, games, models, submissions, functions };
